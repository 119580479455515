import React from 'react'
import classNames from 'classnames/bind'
import { Board } from './board'

import styles from './App.scss'

const css = classNames.bind(styles)

export const App = () => {
  const sounds = [
    {
      title: 'Rock your day!',
      src: 'https://storage.googleapis.com/web-bucket-420/immonen/rock%20your%20day.mp3'
    },
    {
      title: 'Crazy things',
      src: 'https://storage.googleapis.com/web-bucket-420/immonen/crazy%20things.mp3'
    },
    {
      title: 'But remember',
      src: 'https://storage.googleapis.com/web-bucket-420/immonen/but%20remember.mp3'
    },
    {
      title: 'Good',
      src: 'https://storage.googleapis.com/web-bucket-420/immonen/good.mp3'
    },
    {
      title: 'Peepol',
      src: 'https://storage.googleapis.com/web-bucket-420/immonen/peepol.mp3'
    },
    {
      title: 'You need to protec yourself',
      src: 'https://storage.googleapis.com/web-bucket-420/immonen/protec.mp3'
    },
    {
      title: 'Push yourself',
      src: 'https://storage.googleapis.com/web-bucket-420/immonen/push%20yurself.mp3'
    },
    {
      title: 'You need to',
      src: 'https://storage.googleapis.com/web-bucket-420/immonen/you%20need%20to.mp3'
    },
    {
      title: 'Tädää',
      src: 'https://storage.googleapis.com/web-bucket-420/immonen/tadaa.mp3'
    },
    {
      title: 'This is your day',
      src: 'https://storage.googleapis.com/web-bucket-420/immonen/this%20is%20your%20day.mp3'
    },
    {
      title: 'Remember',
      src: 'https://storage.googleapis.com/web-bucket-420/immonen/remember.mp3'
    },
    {
      title: 'Attitude',
      src: 'https://storage.googleapis.com/web-bucket-420/immonen/attitude.mp3'
    }
  ]

  return (
    <div className={css('container-fluid')}>
      <h1 className={css('text-center', 'py-3', 'text-white', 'display-3')}>
        Rock Your Day!!!!
        <small className={css('text-muted')}> Soundboard</small>
      </h1>
      <div className={css('jumbotron-fluid')}>
        <Board sounds={sounds} />
      </div>
      <div className={css('image-container', 'text-center')}></div>
    </div>
  )
}
